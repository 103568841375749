/**
 * Component to show links to blog posts in /blog
 */

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledH2 = styled.h2`
  margin-bottom: 0.3em;
`
const Description = styled.p`
  font-size: 1em;
`

const Box = styled.div`
  &:hover a,
  &:hover h2 {
    color: black;
    text-decoration: none;
    font-weight: 500;
  }
`

const StyledLink = styled(Link)`
  color: black;
  width: 100%;
`

const PostLink = ({ post }) => (
  <Box>
    <StyledLink to={post.frontmatter.slug}>
      <StyledH2>{post.frontmatter.title}</StyledH2>
      <Description>{post.frontmatter.excerpt || post.excerpt}</Description>
    </StyledLink>
  </Box>
)

export default PostLink
