import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Section from "../components/section"
import PostLink from "../components/postLink"
import SEO from "../components/seo"

const Categories = styled(Link)`
  font-size: 1.5em;
  width: 100%;
  display: block;
  padding-bottom: 1em;
`

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => edge.node.frontmatter.publish === true) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <SEO title="Projects" description="List of past projects" />
      <Section
        backgroundColor={"#AED5DE"}
        overflowY={"auto"}
        title={"Projects"}
        height="auto"
      >
        {Posts}
      </Section>
      {/* <Section backgroundColor={'#d4f3ef'} overflowY={'auto'} title={'Images'} height="auto">
            </Section> */}
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            excerpt
            publish
          }
        }
      }
    }
  }
`
